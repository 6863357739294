<template>
  <header class="header">
    <div class="header_logo hidden-sm-and-down">
      <img class="header_logo_style" src="../assets/concept_2019firkant.png" />
    </div>
    <h1 class="hidden-sm-and-down">Concept CPM Calculator</h1>
    <!-- DEVICE BREAK -->
    <div class="header_logo hidden-md-and-up">
      <img class="header_logo_style" src="../assets/concept_2019firkant.png" />
    </div>
    <h2 class="hidden-md-and-up">Concept CPM Calculator</h2>
  </header>
</template>


<script>
export default {
  name: "Header",
};
</script>

<style lang="scss">
@import "@/styles.scss";
</style>
<template>
  <v-footer class="footer">
    <v-container>
      <v-row class="py-0 py-md-8">
        <v-col cols="12" sm="12" md="12" lg="3" class="px-xs-0 text-left">
          <h4 class="font-weight-regular font-18">Copenhagen office (HQ)</h4>
          <p>Toldbodgade 18, 1253 København, Denmark</p>

          <h4 class="font-weight-regular font-18 mt-8">Phone</h4>

          <p>Office : +45 7020 4503</p>

          <h4 class="font-weight-regular font-18 mt-8">Email</h4>

          <div class="d-flex">
            <p class="pr-1">Office :</p>
            <a class="link" href="mailto:info@concept.dk">info@concept.dk</a>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="3" class="px-xs-0 text-left">
          <h4 class="font-weight-regular font-18">Stockholm office</h4>
          <p>Birger Jarlsgatan 18, 114 34 Stockholm, Sweden</p>

          <h4 class="font-weight-regular font-18 mt-8">Phone</h4>

          <p>Office : +46 701 41 8686</p>

          <h4 class="font-weight-regular font-18 mt-8">Email</h4>
          <div class="d-flex">
            <p class="pr-1">Office :</p>
            <a class="link" href="mailto:info@concept.dk">info@concept.dk</a>
          </div>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="3" class="px-xs-0"> </v-col>

        <v-col cols="12" sm="12" md="12" lg="3" class="px-xs-0 text-left">
          <h4 class="font-weight-regular font-18">Socials</h4>

          <div class="social-icons mt-2">
            <a
              href="https://dk.linkedin.com/company/conceptcph"
              target="_blank"
            >
              <i class="mdi mdi-linkedin"></i>
            </a>
          </div>
        </v-col>
      </v-row>

      <div class="footer-bottom-bar font-14">
        <div class="d-block d-md-flex">
          <p class="link">Copyright 2021-2022</p>
          <div class="ml-auto">
            <div
              :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : 'd-flex'"
            >
              <p>
                <a
                  href="https://datacvr.virk.dk/data/visenhed?enhedstype=virksomhed&id=34484139&language=da&soeg=concept:cph&type=undefined"
                  target="_blank"
                  class="link px-4"
                  >Company VAT number</a
                >
              </p>

              <p>
                <a
                  href="https://www.concept.dk/privacy"
                  target="_blank"
                  class="link px-4"
                  >Cookie and Privacy Policy</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </v-footer>
  <!-- -----------------------------------------------
        End Footer
  ----------------------------------------------- -->
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      data: [],
    };
  },
  components: {},
};
</script>
 
<template>
  <v-container style="max-width: 100vw; padding: 0 12px">
    <v-row
      class="hidden-sm-and-down"
      style="
        display: flex;
        flex-wrap: wrap;
        flex: 1 1 auto;
        justify-content: center;
        max-width: 900px;
        margin: 1px auto;
      "
    >
      <v-col cols="3">
        <v-subheader
          style="
          font-family: Google Sans, Roboto, Arial, sans-serif;
            line-height: 1.5rem;
            text-align: left;
            font-size: 1rem;
            letter-spacing: 0.00625em;
            font-weight: 500;
            color: #202124;
            margin: 0;
          "
          >Calculate CPM</v-subheader
        >
      </v-col>
      <v-col cols="2">
        <label for="Budget" class="d-flex pb-5">Budget</label>
        <InputNumber
          locale="de-DE"
          id="locale-user"
          v-model="budgetCPM"
          mode="decimal"
          placeholder="50.000"
          :minFractionDigits="2"
        />
      </v-col>
      <v-col cols="1">
        <p style="margin-top: 40px">/</p>
      </v-col>
      <v-col cols="2">
        <label for="Impressions" class="d-flex pb-5">Impressions</label>
        <InputNumber
          locale="de-DE"
          id="locale-user"
          v-model="impressionsCPM"
          placeholder="500.000"
          mode="decimal"
          :minFractionDigits="2"
        />
      </v-col>
      <v-col cols="1">
        <p style="margin-top: 40px">* 1000</p>
      </v-col>
    </v-row>
    <v-row
      class="hidden-sm-and-down"
      style="
        display: flex;
        flex-wrap: wrap;
        flex: 1 1 auto;
        justify-content: center;
        max-width: 900px;
        margin: 1px auto;
      "
    >
      <v-col cols="3"></v-col>
      <v-col cols="2">
        <label for="CPM" class="d-flex pb-4">CPM =</label>

        <div style="border-bottom: 1px solid grey" class="d-flex pb-1">
          <div class="d-flex align-end">{{ displayCpmResult }}</div>
          <v-icon
            style="margin-left: auto"
            v-if="canCopy"
            @click="copy(displayCpmResult)"
            >mdi-content-copy</v-icon
          >
        </div>
      </v-col>
      <v-col cols="3" class="pl-8" style="display: flex; align-items: center">
      </v-col>
      <v-col cols="1"></v-col>
    </v-row>
    <v-divider class="hidden-sm-and-down"></v-divider>
    <v-row
      class="hidden-sm-and-down"
      style="
        display: flex;
        flex-wrap: wrap;
        flex: 1 1 auto;
        justify-content: center;
        max-width: 900px;
        margin: 1px auto;
      "
    >
      <v-col cols="3">
        <v-subheader
          style="
            font-family: Google Sans, Roboto, Arial, sans-serif;
            line-height: 1.5rem;
            text-align: left;
            font-size: 1rem;
            letter-spacing: 0.00625em;
            font-weight: 500;
            color: #202124;
            margin: 0;
          "
          >Calculate budget</v-subheader
        >
      </v-col>
      <v-col cols="2">
        <label for="Impressions" class="d-flex pb-5">Impressions</label>
        <InputNumber
          locale="de-DE"
          id="locale-user"
          v-model="impressionsBUDGET"
          placeholder="500.000"
          mode="decimal"
          :minFractionDigits="2"
        />
      </v-col>
      <v-col cols="1">
        <p style="margin-top: 40px">*</p>
      </v-col>
      <v-col cols="2">
        <label for="CPM" class="d-flex pb-5">CPM</label>
        <InputNumber
          locale="de-DE"
          id="locale-user"
          v-model="cpmBUDGET"
          placeholder="35"
          mode="decimal"
          :minFractionDigits="2"
        />
      </v-col>
      <v-col cols="1">
        <p style="margin-top: 40px">/ 1000</p>
      </v-col>
    </v-row>
    <v-row
      class="hidden-sm-and-down"
      style="
        display: flex;
        flex-wrap: wrap;
        flex: 1 1 auto;
        justify-content: center;
        max-width: 900px;
        margin: 1px auto;
      "
    >
      <v-col cols="3"></v-col>
      <v-col cols="2">
        <label for="Budget" class="d-flex pb-4">Budget =</label>

        <div style="border-bottom: 1px solid grey" class="d-flex pb-1">
          <div class="d-flex align-end">{{ displayBudgetResult }}</div>
          <v-icon
            style="margin-left: auto"
            v-if="canCopy"
            @click="copy(displayBudgetResult)"
            >mdi-content-copy</v-icon
          >
        </div>
      </v-col>
      <v-col cols="3" class="pb-8" style="display: flex; align-items: center">
      </v-col>
      <v-col cols="1"></v-col>
    </v-row>
    <v-divider class="hidden-sm-and-down"></v-divider>
    <v-row
      class="hidden-sm-and-down"
      style="
        display: flex;
        flex-wrap: wrap;
        flex: 1 1 auto;
        justify-content: center;
        max-width: 900px;
        margin: 1px auto;
      "
    >
      <v-col cols="3">
        <v-subheader
          style="
            font-family: Google Sans, Roboto, Arial, sans-serif;
            line-height: 1.5rem;
            text-align: left;
            font-size: 1rem;
            letter-spacing: 0.00625em;
            font-weight: 500;
            color: #202124;
            margin: 0;
          "
          >Calculate impressions</v-subheader
        >
      </v-col>
      <v-col cols="2">
        <label for="Budget" class="d-flex pb-5">Budget</label>
        <InputNumber
         
          locale="de-DE"
         
          id="locale-user"
          v-model="budgetIMPRESSIONS"
          placeholder="500.000"
          mode="decimal"
          :minFractionDigits="2"
        />
      </v-col>
      <v-col cols="1">
        <p style="margin-top: 40px">/</p>
      </v-col>
      <v-col cols="2">
        <label for="CPM" class="d-flex pb-5">CPM</label>
        <InputNumber
          
          locale="de-DE"
          id="locale-user"
          v-model="cpmIMPRESSIONS"
          placeholder="25"
          mode="decimal"
          :minFractionDigits="2"
        />
      </v-col>
      <v-col cols="1">
        <p style="margin-top: 40px">* 1000</p>
      </v-col>
    </v-row>
    <v-row
      class="hidden-sm-and-down"
      style="
        display: flex;
        flex-wrap: wrap;
        flex: 1 1 auto;
        justify-content: center;
        max-width: 900px;
        margin: 1px auto;
      "
    >
      <v-col cols="3"></v-col>
      <v-col cols="2">
        <label for="Impressions" class="d-flex pb-4">Impressions =</label>

        <div style="border-bottom: 1px solid grey" class="d-flex pb-1">
          <div class="d-flex align-end">{{ displayImpressionsResult }}</div>
          <v-icon
            style="margin-left: auto"
            v-if="canCopy"
            @click="copy(displayImpressionsResult)"
            >mdi-content-copy</v-icon
          >
        </div>
      </v-col>
      <v-col cols="3" class="pb-8" style="display: flex; align-items: center">
      </v-col>
      <v-col cols="1"></v-col>
    </v-row>
    <v-divider class="hidden-sm-and-down"></v-divider>

    <!-- DEVICE BREAK -->
    <!-- Remember that changes needs to be applied to both -->

    <v-col class="hidden-md-and-up">
      <v-col cols="12" style="padding-left: 0">
        <v-subheader
          style="
            display: flex;
            font-family: Google Sans, Roboto, Arial, sans-serif;
            justify-content: flex-start;
            height: 48px;
            text-align: left;
            color: #202124;
            font-size: 1rem;
            font-weight: 500;

            padding: 20px 5px 0 0px;
          "
          >Calculate CPM</v-subheader
        >
      </v-col>

      <v-row>
        <v-col>
          <label for="Budget" class="d-flex pb-5">Budget</label>
          <div class="d-flex">
            <InputNumber
         
          locale="de-DE"
              id="locale-user"
              v-model="budgetCPM"
              mode="decimal"
              suffix=" /"
              placeholder="50.000"
              :minFractionDigits="2"
            />
          </div>
        </v-col>

        <v-col>
          <label for="Impressions" class="d-flex pb-5">Impressions</label>
          <div class="d-flex">
            <InputNumber
        
          locale="de-DE"
              id="locale-user"
              v-model="impressionsCPM"
              mode="decimal"
              suffix=" * 1000"
              placeholder="500.000"
              :minFractionDigits="2"
            />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex py-10">
          <label for="CPM" class="d-flex align-end pr-2 pb-1">CPM=</label>

          <div
            style="border-bottom: 1px solid grey; width: 100%"
            class="d-flex pb-1"
          >
            <div class="d-flex align-end">{{ displayCpmResult }}</div>
            <v-icon
              style="margin-left: auto"
              v-if="canCopy"
              @click="copy(displayCpmResult)"
              >mdi-content-copy</v-icon
            >
          </div>
        </v-col>
      </v-row>
    </v-col>

    <v-col class="hidden-md-and-up">
      <v-divider class="pb-8"></v-divider>
      <v-col cols="12" style="padding-left: 0;">
        <v-subheader
          style="
            display: flex;
            font-family: Google Sans, Roboto, Arial, sans-serif;
            justify-content: flex-start;
            height: 48px;
            color: #202124;
            text-align: left;
            font-size: 1rem;
            font-weight: 500;
            padding: 20px 5px 0 0px;
          "
          >Calculate budget</v-subheader
        >
      </v-col>

      <v-row>
        <v-col cols="12">
          <label for="Impressions" class="d-flex pb-5">Impressions</label>
          <div class="d-flex">
            <InputNumber
          locale="de-DE"
              id="locale-user"
              v-model="impressionsBUDGET"
              mode="decimal"
              placeholder="500.000"
              :minFractionDigits="2"
              suffix=" *"
            />
          </div>
        </v-col>

        <v-col>
          <label for="CPM" class="d-flex pb-5">CPM</label>
          <div class="d-flex">
            <InputNumber
          locale="de-DE"
              id="locale-user"
              v-model="cpmBUDGET"
              mode="decimal"
              placeholder="35"
              suffix=" / 1000"
              :minFractionDigits="2"
            />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex py-10">
          <label for="Budget" class="d-flex align-end pr-2 pb-1">Budget=</label>

          <div
            style="border-bottom: 1px solid grey; width: 100%"
            class="d-flex pb-1"
          >
            <div class="d-flex align-end">{{ displayBudgetResult }}</div>
            <v-icon
              style="margin-left: auto"
              v-if="canCopy"
              @click="copy(displayBudgetResult)"
              >mdi-content-copy</v-icon
            >
          </div>
        </v-col>
      </v-row>
    </v-col>

    <v-col class="hidden-md-and-up">
      <v-divider class="pb-8"></v-divider>
      <v-col cols="12" style="padding-left: 0">
        <v-subheader
          style="
            display: flex;
            font-family: Google Sans, Roboto, Arial, sans-serif;
            justify-content: flex-start;
            height: 48px;
            text-align: left;
            color: #202124;
            font-size: 1rem;
            font-weight: 500;
            padding: 20px 5px 0 0px;
          "
          >Calculate impressions</v-subheader
        >
      </v-col>
      <v-row>
        <v-col>
          <label for="Budget" class="d-flex pb-5">Budget</label>
          <div class="d-flex">
            <InputNumber
          locale="de-DE"
              id="locale-user"
              v-model="budgetIMPRESSIONS"
              mode="decimal"
              placeholder="500.000"
              :minFractionDigits="2"
              suffix=" /"
            />
          </div>
        </v-col>

        <v-col>
          <label for="CPM" class="d-flex pb-5">CPM</label>
          <div class="d-flex">
            <InputNumber
          locale="de-DE"
              id="locale-user"
              v-model="cpmIMPRESSIONS"
              mode="decimal"
              placeholder="25"
              :minFractionDigits="2"
              suffix=" * 1000"
            />
          </div>
        </v-col>
      </v-row>
      <v-row class="pb-5">
        <v-col cols="12" class="d-flex py-10">
          <label for="Impressions" class="d-flex align-end pr-2 pb-1"
            >Impressions=</label
          >

          <div
            style="border-bottom: 1px solid grey; width: 100%"
            class="d-flex pb-1"
          >
            <div class="d-flex align-end">{{ displayImpressionsResult }}</div>
            <v-icon
              style="margin-left: auto"
              v-if="canCopy"
              @click="copy(displayImpressionsResult)"
              >mdi-content-copy</v-icon
            >
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import InputNumber from "primevue/inputnumber";
export default {
  name: "Calculator",
  data: () => ({
    canCopy: false,
    impressionsCPM: null,
    budgetCPM: null,
    impressionsBUDGET: null,
    cpmBUDGET: null,
    cpmIMPRESSIONS: null,
    budgetIMPRESSIONS: null,
  }),
  created() {
    this.canCopy = !!navigator.clipboard;
  },
  methods: {
    // prettifyNumber(input) {
    //   if (!input) return;
    //   return `${this.formatNumber(parseFloat(input))}`;
    // },
    formatNumber: function (value) {
      if (!value) return;
      const rounded = Math.round(value * 100) / 100;
      return rounded.toLocaleString("da-dk");
      // .replace(".", ",")
      // .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    async copy(s) {
      try {
        console.log(s);
        await navigator.clipboard.writeText(s);
      } catch (error) {
        console.log("we have rror", error);
      }
    },
  },

  components: {
    InputNumber: InputNumber,
  },
  computed: {
    displayCpmResult() {
      return this.formatNumber(this.cpmResult);
    },
    displayBudgetResult() {
      return this.formatNumber(this.budgetResult);
    },
    displayImpressionsResult() {
      return this.formatNumber(this.impressionsResult);
    },
    cpmResult() {
      if (!this.budgetCPM || !this.impressionsCPM) return;
      console.log(this.budgetCPM);
      return (this.budgetCPM / this.impressionsCPM) * 1000;
    },
    budgetResult() {
      if (!this.impressionsBUDGET || !this.cpmBUDGET) return;
      return this.impressionsBUDGET * (this.cpmBUDGET / 1000);
    },
    impressionsResult() {
      if (!this.budgetIMPRESSIONS || !this.cpmIMPRESSIONS) return;
      return (this.budgetIMPRESSIONS / this.cpmIMPRESSIONS) * 1000;
    },
  },
};
</script>

<style lang="scss">
@import "@/styles.scss";

.v-text-field fieldset,
.v-text-field .v-input__control,
.v-text-field .v-input__slot {
  border: 0 !important;
}

.p-inputnumber {
  align-items: center;
  color: inherit;
  display: flex;
  margin-bottom: 8px;
  min-height: inherit;
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
  border-bottom: 1px solid grey;
}
input:focus,
textarea:focus,
select:focus {
  outline: none;
}
</style>

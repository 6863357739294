<template>
  <v-app>
    <Header />
    <v-main>
      <v-divider></v-divider>
      <Calculator />
      <!-- <Calculatortwo/> -->
    </v-main>
    <v-divider class="pb-6 hidden-md-and-up"></v-divider>
   <Footer/>
  </v-app>
</template>

<script>
import Calculator from "./components/Calculator.vue";
// import Calculatortwo from "./components/Calculatortwo.vue";
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",

  components: {
    Calculator,
    // Calculatortwo,
    Header,
    Footer
  },

  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
@import "@/styles.scss";
</style>
